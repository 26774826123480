import React from 'react';

const News = () => (
  <section id="news" className="py-12 bg-white text-gray-800">
    <div className="container mx-auto px-4 text-center">
      <h3 className="text-3xl font-bold mb-8">Latest Cricket News</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <NewsCard title="India Wins Series" description="India clinched the series with a thrilling finish." />
        <NewsCard title="Top Performers of the Season" description="A roundup of the top performers of the cricket season." />
        <NewsCard title="Upcoming ICC Events" description="Get ready for the ICC Cricket World Cup 2025!" />
      </div>
    </div>
  </section>
);

const NewsCard = ({ title, description }) => (
  <div
  onClick={() => window.open('https://wa.link/indraadsgg', '_blank')}
  className="p-6 rounded-lg shadow-lg bg-gray-100 hover:bg-gray-200 cursor-pointer transition transform hover:scale-105">
    <h4 className="text-xl font-semibold mb-2">{title}</h4>
    <p>{description}</p>
  </div>
);

export default News;
