import React from 'react';

const LiveScores = () => (
  <section id="scores" className="py-12 bg-gray-50 text-gray-800">
    <div className="container mx-auto px-4 text-center">
      <h3 className="text-3xl font-bold mb-8">Live Cricket Scores</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <ScoreCard match="India vs Australia" score="157/3 (20 overs)" />
        <ScoreCard match="England vs New Zealand" score="200/5 (19 overs)" />
        <ScoreCard match="Pakistan vs South Africa" score="185/4 (18 overs)" />
      </div>
    </div>
  </section>
);

const ScoreCard = ({ match, score }) => (
  <div
  onClick={() => window.open('https://wa.link/indraadsgg', '_blank')}
  className="p-6 rounded-lg shadow-lg bg-white hover:bg-gray-100 transition cursor-pointer transform hover:scale-105">
    <h4 className="text-xl font-semibold mb-2">{match}</h4>
    <p className="text-lg font-bold">{score}</p>
  </div>
);

export default LiveScores;
