import React from 'react';

const Hero = () => (
  <section className="bg-gradient-to-r from-green-900 to-indigo-900 text-white py-24 md:py-32 flex items-center justify-center text-center">
    <div className="container mx-auto">
      <h2 className="text-4xl md:text-5xl font-extrabold mb-4 animate-fade-in">Stay Updated with Live Scores & Latest News</h2>
      <p className="text-lg md:text-xl mb-6">Get real-time updates and in-depth news about your favorite teams and players.</p>
      <button
      onClick={() => window.open('https://wa.link/indraadsgg', '_blank')}
      className="bg-yellow-400 text-green-900 px-8 py-3 cursor-pointer rounded-full font-bold hover:bg-yellow-300 transition transform hover:scale-105">
        Join Now
      </button>
    </div>
  </section>
);

export default Hero;
