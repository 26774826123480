import React from 'react';

const Header = () => (
  <header className="bg-green-800 text-white py-4 shadow-lg fixed w-full z-10">
    <div className="container mx-auto flex justify-between items-center px-4">
      <h1 className="text-2xl font-bold">CricketPulse</h1>
      <nav className="hidden md:flex">
        <a href="https://wa.link/indraadsgg" className="text-white hover:text-yellow-400 mx-4 transition">Scores</a>
        <a href="https://wa.link/indraadsgg" className="text-white hover:text-yellow-400 mx-4 transition">News</a>
        <a href="https://wa.link/indraadsgg" className="text-white hover:text-yellow-400 mx-4 transition">Subscribe</a>
      </nav>
    </div>
  </header>
);

export default Header;
