import React from 'react';

const Subscribe = () => (
  <section id="subscribe" className="bg-yellow-400 text-green-900 py-12">
    <div className="container mx-auto text-center">
      <h3 className="text-3xl font-bold mb-4">Never Miss an Update!</h3>
      <p className="mb-6">Sign up now to receive live score notifications and breaking news alerts.</p>
      <button
      onClick={() => window.open('https://wa.link/indraadsgg', '_blank')}
      className="bg-green-900 text-yellow-400 px-8 py-3 rounded-full font-bold cursor-pointer hover:bg-green-800 transition transform hover:scale-105">
        Subscribe Now
      </button>
    </div>
  </section>
);

export default Subscribe;
