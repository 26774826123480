import React from 'react';
import Header from "../CricketPulse/Header"
import Hero from "../CricketPulse/Hero";
import News from "../CricketPulse/News"
import Subscribe from "../CricketPulse/Subscribe"   
import LiveScores from "../CricketPulse/Livescore"  



const LandingPage = () => (
  <div className="min-h-screen bg-gray-100 font-sans">
    <Header/>
    <main className="pt-16">
      <Hero/>
      <News/>
      <LiveScores/>
      <Subscribe/>
    </main>
  </div>
);

export default LandingPage;